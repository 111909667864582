import React, { Fragment } from "react";
import Link from "next/link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import darkTheme from '../src/theme';

const footerLegalContainer = {           
  '& p': {
    fontSize: 14,    
    color: darkTheme.palette.primary.location,
    lineHeight: 1.7,    
    fontWeight: 100,       
  },                
};


const FooterLegal = () => {
  return (
    <div className="clubs" style={{ marginTop: "40px" }}>
      <h2 style={{ marginTop: "0", color: "#9e9e9e", fontWeight:100 }}>Legal</h2>
      <Fragment>
        <Stack
          sx={{
            flexDirection: {
              md: "row",
              sm: "column",
            },
            justifyContent: {
              md: "space-between",
              sm: " flex-start",
            },
          }}
        >                    
        <Typography component="div" sx={footerLegalContainer}>
        <Typography component="p">
            <Link href={"/privacy"} passHref>
            <a>Privacy Policy</a>
            </Link>
        </Typography>
        <Typography component="p">
            <Link href={"/refund"} passHref>
            <a>Refund Policy</a>
            </Link>
        </Typography>                                                              
        <Typography component="p">
            <Link href={"/cookies"} passHref>
            <a>Cookie Policy</a>
            </Link>
        </Typography>
        <Typography component="p">
            <Link href={"/terms"} passHref>
            <a>Terms of Use</a>
            </Link>
        </Typography>
        </Typography>            
        </Stack>
      </Fragment>
    </div>
  );
};
export default FooterLegal;
