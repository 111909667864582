import React, { Fragment } from "react";
import Typography from '@mui/material/Typography'
import darkTheme from '../src/theme';

const footerAboutContainer = {           
  '& h5': {
    fontSize: 14,
    marginTop: darkTheme.spacing(5),
    color: darkTheme.palette.primary.location,     
    lineHeight: 1.7,    
    fontWeight: 100,       
  },                
};


const FooterAbout = () => {    
  return (    
    <div className="">           
      <Fragment>            
        <Typography component="div" sx={footerAboutContainer}>
          <Typography variant="h5">Clubr is a Nightlife Discovery Platform exclusively designed for live Events, Nightclubs, Pubs, Bars, Breweries, Live Music Venues and Lounges.  Our platform connects clubbers with the Best Live Music Venues and event organisers to discover & book online tickets and reserve tables for upcoming events empowering them to make informed decisions and offering a seamless clubbing experience before, during, and after the shows.</Typography> 
        </Typography>            
      </Fragment>                 
    </div>              
  );
}
export default FooterAbout;