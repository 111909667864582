import React, { Fragment } from "react";
import Link from "next/link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import darkTheme from '../src/theme';

const footerCompanyContainer = {           
  '& p': {
    fontSize: 14,    
    color: darkTheme.palette.primary.location,   
    lineHeight: 1.7,    
    fontWeight: 100,       
  },                
};


const FooterCompany = () => {
  return (
    <div className="clubs" style={{ marginTop: "40px" }}>
      <h2 style={{ marginTop: "0", color: "#9e9e9e", fontWeight:100 }}>Company</h2>
      <Fragment>
        <Stack
          sx={{
            flexDirection: {
              md: "row",
              sm: "column",
            },
            justifyContent: {
              md: "space-between",
              sm: " flex-start",
            },
          }}
        >         
          <Typography component="div" sx={footerCompanyContainer}>
            <Typography component="p">
              <Link href={"/about"} passHref>
                <a>About Clubr</a>
              </Link>
            </Typography>
            <Typography component="p">
              <Link href={"/contact"} passHref>
                <a>Contact</a>
              </Link>
            </Typography>                              
            <Typography component="p">
              <Link href={"/careers"} passHref>
                <a>Careers</a>
              </Link>
            </Typography>
            {/* <Typography component="p">
              <Link href={"https://tribe.clubr.in"} passHref>
                <a>Clubr Tribe</a>
              </Link>
            </Typography> */}
            <Typography component="p">
              <Link href={"/backstage"} passHref>
                <a>Clubr Backstage</a>
              </Link>
            </Typography>
            <Typography component="p">
              <Link href={"/link"} passHref>
                <a>Clubr Link</a>
              </Link>
            </Typography>
          </Typography>            
        </Stack>
      </Fragment>
    </div>
  );
};
export default FooterCompany;
