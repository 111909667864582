import React, { Fragment } from "react";
import Link from "next/link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import darkTheme from '../src/theme';

const footerResourceContainer = {           
  '& p': {
    fontSize: 14,    
    color: darkTheme.palette.primary.location, 
    lineHeight: 1.7,    
    fontWeight: 100,       
  },                
};

const FooterResources = () => {
  return (
    <div className="clubs" style={{ marginTop: "40px" }}>
      <h2 style={{ marginTop: "0", color: "#9e9e9e", fontWeight:100 }}>Resources</h2>
      <Fragment>
        <Stack
          sx={{
            flexDirection: {
              md: "row",
              sm: "column",
            },
            justifyContent: {
              md: "space-between",
              sm: " flex-start",
            },
          }}
        >          
            <Typography component="div" sx={footerResourceContainer}>                                      
                <Typography component="p">
                    <Link href={"/mag"} passHref>
                    <a>Clubr Mag</a>
                    </Link>
                </Typography>
                <Typography component="p">
                    <Link href={"/guides"} passHref>
                    <a>Whitepapers & Guides </a>
                    </Link>
                </Typography>
                <Typography component="p">
                    <Link href={"/stories"} passHref>
                    <a>Customer Stories</a>
                    </Link>
                </Typography>
            </Typography>            
        </Stack>
      </Fragment>
    </div>
  );
};
export default FooterResources;
