import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FacebookIcon from '@mui/icons-material/FacebookTwoTone';
// import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FooterAbout from "../FooterAbout";
import FooterQuickLinks from "../FooterQuickLinks";
import styles from '../Footer.module.css'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Stack from '@mui/material/Stack'
import moment from 'moment'
import Image from 'next/image'
const Footer = () => {
  
  const footerBg = {
    backgroundImage:`url("../images/footer/footer.png")`,
    backgroundPosition:'center center',
    backgroundSize:'cover',
    padding: `70px 0 0 0`,
  }

  return (
    <Typography component="div" className={styles.footer}>
      <Typography component="div" sx={footerBg}>
      <Container>
        <Grid container spacing={0}>               
          <Box>
            <Grid container spacing={10}>
              <Grid item xs={12} xl={5} lg={5} md={5} sm={5}>
                <FooterAbout />
              </Grid>                            
              <Grid item xs={12} xl={7} lg={7} md={7} sm={7}>
                <FooterQuickLinks />                
              </Grid>              
            </Grid>
          </Box>                                              
        </Grid>
      </Container>
      <Typography component="p" sx={{
        pb: 4,
        pt: 5,        
        textAlign: 'center',
        fontSize:'12px'
      }}>             
          * This content is intended for audience above legal drinking age as
          specified by their state/country of residence. Enjoy & Share
          Responsibly             
      </Typography>
      </Typography>
      
      <Typography component="div" className={styles.footer_secondary}>
        <Container>          
          <Grid container spacing={0} sx={{ justifyContent: 'space-between' }}>
            <Grid item xs={12} lg={6} sm={12} md={6} xl={6} sx={{ alignSelf: 'center' }}>
                <Stack display="flex" flexDirection="row">
                  <Typography component="p" sx={{ alignSelf:"center", fontSize:'12px' }}>
                    <Image src="/images/new-logo.webp" alt="clubr-logo" width={65} height={26} layout="fixed" /> 
                  </Typography>
                  <Typography component="p" sx={{ alignSelf:"center", fontSize:'12px', pt:1, ml:{md:4, lg:4, xl:4, sm:3} }}>
                    &nbsp;&nbsp;By Continuing Past this page, you agree to our <Link href="/terms" sx={{color:'#fff !important', fontWeight:'200'}} >Terms of Use</Link>
                  </Typography>
                </Stack>
              </Grid>
             
            <Grid item xs={12} lg={6} md={6} xl={6} sx={{ alignSelf: 'center' }}>
              <Stack display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography component="p" sx={{ alignSelf:"center", fontSize:'12px', pt:1}}>
                  &copy;&nbsp;Clubr&nbsp;{moment(new Date()).format('YYYY')}
                  </Typography>
                <Typography component="div" sx={{ alignSelf: "center", fontSize: '12px', pt: 1 }}>
                    <Stack display="flex" flexDirection="row" alignItems="center">
                      <Image src="/images/india.png" alt="flag" width={24} height={20} pb={2} />&nbsp;&nbsp;
                      <Typography component="p" href="/terms" sx={{ color: '#fff !important', fontWeight: '200' }} >India</Typography>
                    </Stack>
                  </Typography>
                  <Typography component="div" className={styles.follow}>
                    <List sx={{
                        px:5
                      }}>                    
                        <ListItem sx={{
                          justifyContent:'center'
                        }}>
                          <Link href="https://www.facebook.com/Clubrapp" target="_blank"  rel="noreferrer"> 
                            <FacebookIcon sx={{ color: '#fff' }} />
                          </Link>
                        </ListItem>
                        {/* <ListItem sx={{
                          justifyContent:'center'
                        }}> */}
                        {/* <Link href="https://twitter.com/Clubr14" target="_blank"  rel="noreferrer"> 
                            <TwitterIcon sx={{ color: '#fff' }} />                          
                        </Link> */}
                        {/* </ListItem> */}
                        <ListItem sx={{
                          justifyContent:'center'
                        }}>
                        <Link href="https://www.instagram.com/clubr.in/" target="_blank"  rel="noreferrer"> 
                            <InstagramIcon sx={{ color: '#fff' }} />                          
                        </Link>
                        </ListItem>
                        {/* <ListItem sx={{
                          justifyContent:'center'
                        }}>
                        <Link href="https://www.linkedin.com/company/clubrapp" target="_blank"  rel="noreferrer"> 
                            <LinkedInIcon sx={{ color: '#fff' }} />                          
                          </Link>
                        </ListItem> */}
                      </List>
                  </Typography>
                </Stack>
              </Grid>              
            </Grid>          
          </Container>
        </Typography>
      <Container>
        <Typography component="div" className={styles.terms_section} sx={{
          py:4
        }}>          
            <Typography variant="h6">Discover Live Music Venues near you: </Typography>
            <Typography component="p">Discover live music venues, best pubs, nightclubs, breweries, Resto Bars, and Lounges near you. Clubr curates information from these best live entertainment venues like Food Menu, Drinks Menu, Live Sports Screening, Outdoor Seating, Timings, directions, Cuisine, and Dress code to help clubbers make informed decisions to party. Top search results for live Music venues for clubr:  nightclubs near me, bars and pubs near me,  disco near me, music live near me, live music bar, gastro pub near me,</Typography>

            <Typography variant="h6">Discover & Book online tickets for upcoming live events near you:</Typography>
            <Typography component="p">Clubr helps you discover upcoming live music events and Dj nights at your favourite pub near you. Clubr curates the best events in the Nightlife scene and enable online booking of tickets for the event. We gather crusial information about the lineup and timings to help you be informed about the live event. Top Search results for live events for clubr: Standup Comedy near me, DJ Night near me, Ladies Night near me, karaoke night near me, Live Band show events </Typography>

            <Typography variant="h6">Discover Deals at premium Nightlife Venues :</Typography>
            <Typography component="p">Clubr is a real-time dedicated Nightlife platform that brings you the latest offers and deals from the Nightclubs, Pubs & breweries you love. We curate the best offers  from nightlife venues daily like Happy Hours, MRP Nights, Buy 1 Get One Free, Free Drinks, Combo’s, Packages, Brunch’s, Pub Lunch, Deals and more so that you can choose which Nightclub or brewery offers better deals for you near you to party. </Typography>

            <Typography variant="h6">Book VIP tables online at Live Music Events :</Typography>
            <Typography component="p">Clubr is the only nightlife discovery platform wherein you can reserve tables from the pubs & clubs you love with just a click of a button. Clubr connects Live Music Venues in real-time, users table reservation request is passed directly reaches to pub manager as soon as you hit the submit button so that it helps you choose the live music venues in your locality that offer tables even during the peak hours of the weekend. Clubr aims to redefine the clubbing experience by bringing the best of nightlife for you.</Typography>
            
            <Typography variant="h6">Clubr Backstage: Whitelabel Ticketing Platform for Nightclubs & Event Organisers:</Typography>
            <Typography component="p">Clubr Backstage is an omnichannel ticketing platform with a mobile-first approach crafted for the Live Entertainment Industry that empowers them to build digital identity and achieve sustainability. Clubr backstage empowers Nightlife Venues and Event organisers with new digital capabilities to sell tickets, Deals and Merchandise from their website. Venues and organisers can now host their exclusive website/ Mobile Apps on their brand name and start selling tickets in under 30 minutes with a simple subscription fee without investing heavily in technology with our Whitelabel offering.</Typography>            
        </Typography>
      </Container>
    </Typography>
  );
}

export default Footer