import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FooterCompany from "../components/FooterCompany";
import FooterLegal from "../components/FooterLegal";
import FooterResources from "../components/FooterResources";

const FooterQuickLinks = () => {
  return (
    <Typography component="div">             
        <Grid container>                                       
            <Grid item xs={12} xl={4} lg={4} md={4} sm={6}>
                <FooterCompany />                
            </Grid>
            <Grid item xs={12} xl={4} lg={4} md={4} sm={6}>
                <FooterLegal />                
            </Grid>
            <Grid item xs={12} xl={4} lg={4} md={4} sm={6}>
                <FooterResources />                
            </Grid>
        </Grid>         
    </Typography>
  );
};
export default FooterQuickLinks;
